<template>
  <!-- Footer Start -->
  <footer
    class="container-lg p-relative p-3 px-md-4 mx-auto my-5 text-muted"
    data-aos="zoom-in-up"
  >
    <nav
      class="
        d-none d-lg-block
        navbar navbar-expand-lg navbar-light
        footer-nav
        px-sm-4 px-lg-4
        pb-lg-7
        pt-md-4
        container-lg
      "
    >
      <div
        class="collapse navbar-collapse justify-content-lg-start"
        id="navbarNavDropdown"
      >
        <ul class="navbar-nav" data-aos="zoom-in-right">
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/Products">Products</a>
          </li>
          <!-- <li class="nav-item">
          <a class="nav-link px-lg-4" href="#">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link px-lg-4" href="#">In The News</a>
        </li> -->
          <li class="nav-item">
            <a class="nav-link px-lg-4" href="/contact">Contact</a>
          </li>
        </ul>
        <a class="navbar-brand" href="/" data-aos="fade-up"
          ><img src="@/assets/logo-icon-atomic-x-red.png" alt="Logo Atomic X"
        /></a>
      </div>
    </nav>

    <div class="row flex-md-row justify-content-md-between">
      <p class="col-md-6 text-center text-md-start">
        © 2022 Copyright AtomicX. All Rights Reserved.
      </p>
      <p class="col-md-6 text-center text-md-end">
        Web Design & Development by
        <a href="https://www.atomicmotion.com">Atomic Motion</a>
      </p>
    </div>
  </footer>
  <!-- Footer End -->
</template>

<script>
export default {};
</script>

<style></style>
